import React from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

function DashboardAdmin() {
    return (
        <>
            <Nav />
            <div className='page'>   
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">Dashboard Admin</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Page Empty</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        {/* <h1>ขออภัย</h1>
                        <h4>ระบบอยู่ในระหว่างการพัฒนา</h4> */}
                    </div>
                </div>
                <Footer />
            </div>    
                    
            
        </>
    )
}

export default DashboardAdmin
